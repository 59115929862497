import NavBar from "./NavBar";
import aboutus from "../Assets/Images/AboutUs/about-img.svg"
import aboutus1 from "../Assets/Images/AboutUs/about us1.gif"
import rightArrow from "../Assets/Images/Tech/Icons/right-white-arrow-icon.svg"
import howworks from "../Assets/Images/Tech/AboutUs/how-works.png"
import Footer from "./Footer"
import howItWorks from "../Assets/Images/AboutUs/HowItWorks.mp4"
import howWorks from "../Assets/Images/AboutUs/how-works1.mp4"
import banner from "../Assets/Images/Banner/about us banner.mp4"
import React, { useState, useEffect } from "react";


const AboutUs = () => {
    const handleLanguageSelect = (languageUrl) => {
        // Directly navigate to the provided URL
        window.location.href = languageUrl;
    };
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div>
            <NavBar navIndex="Aboutus" />
            <div className="col-md-12" style={{ position: "relative", height: "250px" }}>
                <video src={banner} className="banner-video" autoPlay loop muted style={{ position: "absolute", height: "250px" }}></video>
                {/* Overlay text container */}

                <div className="banner-text" >
                    <h1 style={{ marginTop: "15px", fontFamily: "Montserrat, sans-serif", }}>About Us</h1>
                </div>
            </div>
            <section className="section-why-choose-us light-red-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pe-3">
                                <img src={aboutus1} className="img-fluid" />
                            </div>

                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <h6>Course Info</h6> */}
                                    <h2>Learn English
                                        <br></br>
                                        <span className="text-primary">Any Where ... Any Time...</span>
                                    </h2>
                                    <div className="bullet-arrow">
                                        <ul>
                                            <li>Anglofone's journey began in 2019 with a simple yet powerful mission: to make English accessible to all.</li>
                                            <li>Over the years, we’ve helped 50,000+ learners transform their lives through our Online English classes.</li>
                                            <li>We have carefully created our courses in order to make the learners overcome the fear of English, and speak confidently.</li>

                                            <li>Learning English creates new opportunities.</li>
                                            <li>Anglofone is here to make those opportunities available to everyone.</li>


                                        </ul>
                                        
                                    </div>
                                    <button className="btn btn-danger btn-theme d-block mt-4" type="button" data-bs-toggle="modal"
                                            data-bs-target="#customModalinAbout">
                                            {`${"Join Now "}`}
                                            <img src={rightArrow} alt="grow" />
                                        </button>

                                             {/* Custom Modal */}
                                             <div
                                            className="modal fade"
                                            id="customModalinAbout"
                                            tabIndex="-1"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content custom-modal-content">
                                                    <div className="modal-header custom-modal-header">
                                                        <h5 className="modal-title">Select Language</h5>
                                                        <i
                                                            className="custom-close-btn"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            &times;
                                                        </i>


                                                    </div>
                                                    <div className="modal-body custom-modal-body">
                                                        <ul className="language-list">
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/cd6igd")
                                                                }
                                                                className="language-item" style={{ listStyle: "none" }}
                                                            >
                                                                Malayalam
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/rirnn2")
                                                                }
                                                                className="language-item"
                                                            >
                                                                Tamil
                                                            </li>
                                                            <li
                                                                onClick={() =>
                                                                    handleLanguageSelect("https://wa.link/4la105")
                                                                }
                                                                className="language-item"
                                                            >
                                                                Telugu
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showArrow && (
    <button
        className="back-to-top"
        onClick={scrollToTop}
        aria-label="Back to top"
    >
        ↑
    </button>
)}
            <div className="section-why-choose-us" style={{ backgroundColor: "#F3F3F3" }}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <h6>OURSTEPS</h6> */}
                                    <h2>Our
                                        <span className="text-primary"> Journey</span>
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    {/* <img src={howworks} className="img-fluid d-block mx-auto" /> */}
                                    <video src={howWorks} className="img-fluid d-block mx-auto" autoPlay loop muted >
                                    </video>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <footer>
                <Footer />
            </footer>
        </div>

    )
}


export default AboutUs;