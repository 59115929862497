import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import "../CSS/NavBar.css"



const NavigationBar = (props) => {

    const [activeLink, setActiveLink] = useState("Home");
    

    const navigate = useNavigate();

    console.log("tttttmnjdfjdnjfd", props.navIndex)


    // Function to handle setting the active link
    useEffect(() => {
        setActiveLink(props.navIndex);
    }, [])



    return (
        <div className="Container">
            <nav class="navbar fixed-top navbar-expand-lg  bg-body-tertiary">
                <div class="container-fluid">
                    <a href="/home">
                        <img className="p-2" src="https://infoc-eb658.web.app/assets/images/tech/logo.svg" style={{ cursor: "pointer" }} />
                    </a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                        <div class='justify-content-start'>
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a className="nav-link" href="/home" style={{ color: activeLink === "Home" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>Home</a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: activeLink === "Courses" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>
                                        Courses
                                    </a>
                                    <ul class="dropdown-menu" >
                                        <li><a class="dropdown-item" href="/Course/Foundation" >Foundation</a></li>
                                        <li><a class="dropdown-item" href="/Course/Beginner">Beginner</a></li>
                                        <li><a class="dropdown-item" href="/Course/Mediate">Mediate</a></li>
                                        <li><a class="dropdown-item" href="/Course/Speak-Out">Speak-out</a></li>
                                        {/* <li><a class="dropdown-item" href="/Course/IELTS">IELTS</a></li>
                                        <li><a class="dropdown-item" href="/Course/Seed">Seed</a></li>
                                        <li><a class="dropdown-item" href="/Course/Interview">Interview</a></li>
                                        <li><a class="dropdown-item" href="/Course/Phonics">Phonics</a></li> */}

                                    </ul>
                                </li>
                                <li class="nav-item dropdown" >
                                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: activeLink === "Region" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>
                                        Languages
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="/Region/Tamil">Tamil</a></li>
                                        <li><a class="dropdown-item" href="/Region/Malayalam">Malayalam</a></li>
                                        <li><a class="dropdown-item" href="/Region/Telugu">Telugu</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item" >
                                    <a className="nav-link" aria-current="page" href="/Aboutus" style={{ color: activeLink === "Aboutus" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>About Us</a>
                                </li>
                                <li class="nav-item" >
                                    <a className="nav-link" aria-current="page" href="/Career" style={{ color: activeLink === "Career" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>Career</a>
                                </li>
                                <li class="nav-item" >
                                    <a className="nav-link" aria-current="page" href="/ContactUs" style={{ color: activeLink === "Contactus" ? "rgb(180 28 4)" : "black", fontSize: "15px", fontWeight: "bold" }}>Contact Us</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" aria-current="page" href="#" > <FontAwesomeIcon icon={faPhone} style={{ fontSize: '25px', color: "#024988" }} /></a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}


export default NavigationBar;