import "../CSS/TermsAndConditions.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import React, { useState,useEffect } from "react";

const TermsAndConditions = () => {
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };


    return (
        <div>
            <NavBar />
            <div className="terms-container">
                <h1 className="terms-heading">Terms and <span>Conditions
                </span></h1>
                <div className="custom-underline"></div>
                <p className="company-name">AngloFone EdTech Pvt. Ltd</p>

                <p className="intro">
                    Welcome to AngloFone Education's WhatsApp English Learning Module. By
                    engaging with our courses, you agree to comply with the following terms
                    and conditions:
                </p>

                <div className="terms-content">
                    <section>
                        <h2>1. Contacting Your Course Advisor</h2>

                        <ul>
                            <li>
                                <p>If you encounter issues or have suggestions for improvement,
                                    please feel free to contact your respective <strong>"Course Advisor"</strong>{" "}
                                    directly.</p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>2. Course Rejoining and Validity</h2>

                        <ul>
                            <li>
                                <p>
                                    You have a validity period of <strong>"6 months"</strong> to rejoin the course
                                    in case of any issues from your side.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Rejoining after the 6-month validity period will be considered a{" "}
                                    <strong>"New Admission"</strong>.
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>3. Batch Changing Option</h2>
                        <ul>
                            <li>
                                <p>
                                    The <strong>Batch changing option</strong> is available only once.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Repeating the batch change twice or more requires a payment of a{" "}
                                    <strong>"Security Deposit"</strong> of INR 2000 (refundable upon successful
                                    course completion).
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>4. Leaving the Group</h2>
                        <ul>
                            <li>
                                <p>
                                    If you need to leave the group, it is your responsibility to inform
                                    your <strong>"Course Advisor"</strong> with the reason. They can assist you in
                                    finding an alternative option.
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>5. Class Attendance</h2>
                        <ul>
                            <li>
                                <p>
                                    If you are unable to attend classes, kindly inform your respective{" "}
                                    <strong>"Trainer and the Course Advisor"</strong>. This communication helps us
                                    understand your situation.
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>Speaking Activities</h2>
                        <ul>
                            <li>
                                <p>
                                    The primary benefit of this course is the <strong>"Calling Activity"</strong>.
                                    Please be available for your calling activity, as it significantly
                                    contributes to improving your fluency.
                                </p>
                            </li>
                            <li>
                                <p>
                                    To enhance your public speaking skills, actively participate in the
                                    live sessions we offer.
                                </p>
                            </li>
                        </ul>


                    </section>

                    {showArrow && (
    <button
        className="back-to-top"
        onClick={scrollToTop}
        aria-label="Back to top"
    >
        ↑
    </button>
)}

                    <section>
                        <h2>7. Confidentiality</h2>
                        <ul>
                            <li>
                                <p>
                                    Do not share your <strong>"Lecture Notes"</strong> with anyone.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Creating groups and sharing class content with friends or relatives is
                                    strictly prohibited.
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>8. Group Interaction</h2>
                        <ul>
                            <li>
                                <p>Interact only with your respective trainer within the group.</p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>9. Trainer Working Hours</h2>
                        <ul>
                            <li>
                                <p>
                                    Trainer working hours are from <strong>5:00 AM to 12:00 AM</strong>.
                                    Responses after <strong>12:00 AM</strong> will be addressed the next day.
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>10. Policy Updates</h2>
                        <ul>
                            <li>
                                <p>
                                    AngloFone Education reserves the right to modify or amend these
                                    terms and conditions at any time. Any changes will be effective
                                    immediately upon posting on our website.
                                </p>
                            </li>
                        </ul>

                    </section>
                </div>

                <p className="conclusion">
                    By participating in AngloFone EdTech Pvt. Ltd's WhatsApp English Learning
                    Institute, you acknowledge and agree to comply with these terms and
                    conditions.
                </p>
            </div>
            <footer>
                <Footer />
            </footer>



        </div>
    );
};

export default TermsAndConditions;
