import NavBar from "./NavBar"
import "../CSS/ContactUs.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBusinessTime, faUserTie } from "@fortawesome/free-solid-svg-icons";
import phoneIcon from "../Assets/Images/Tech/Icons/phone-icon.svg"
import timeIcon from "../Assets/Images/Tech/Icons/time-icon.svg"
import mailIcon from "../Assets/Images/Tech/Icons/mail-icon.svg"
import locationIcon from "../Assets/Images/Tech/Icons/map-icon.svg"
import ContactLady from "../Assets/Images/Tech/Contact/contact-lady.svg"
import Contact1 from "../Assets/Images/Tech/Contact/1.png"
import Contact2 from "../Assets/Images/Tech/Contact/6.png"
import Contact3 from "../Assets/Images/Tech/Contact/7.png"
import Footer from "./Footer";
import banner from "../Assets/Images/Banner/about us banner.mp4"
import React, { useState, useEffect } from "react";



const ContactUs = () => {
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <div>
            <NavBar navIndex="Contactus" />
            <div className="col-md-12" style={{position: "relative", height: "250px" }}>
                <video src={banner} className="banner-video" autoPlay loop muted style={{position:"absolute",height:"250px"}}></video>
                {/* Overlay text container */}

                <div className="banner-text" >
                <h1 style={{fontFamily: "Montserrat, sans-serif",marginTop:"15px"}}>Contact Us</h1>
                </div>
            </div>

            <section class="section-why-choose-us light-red-bg section-contact">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            {/* <h6>Contact Us</h6> */}
                            <h2>Feel free to <span class="theme-red-text">Contact us</span> </h2>
                            {/* <p>Et purus duis sollicitudin dignissim habitant. Egestas nulla quis venenatis cras sed eu massa Et purus duis
                            </p> */}
                        </div>
                    </div>
                    <div class="row contact-details mt-5">
                        <div class="col-md-12">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#home"><span><FontAwesomeIcon icon={faBook} /></span>
                                        Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#menu1"><span><FontAwesomeIcon icon={faBusinessTime} /></span>
                                        Business</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#menu2"><span><FontAwesomeIcon icon={faUserTie} /></span>
                                        Jobs</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="container tab-pane active">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Courses</h4>
                                                    <p style={{color:"black"}}>For inquiries about our courses and to discover how we can help you achieve your goals, please contact us.
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Requesting A Call:</label>
                                                            <p style={{color:"black"}}>+91 8148628386</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>E-mail:</label>
                                                            <p style={{color:"black"}}>admin@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Monday to friday</label>
                                                            <p style={{color:"black"}}>9 am - 8 pm</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Location:</label>
                                                            <p style={{color:"black"}}>Race Course, Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <img src={Contact1} style={{ maxHeight: "300px" }} class="img-fluid-contact" />
                                        </div>
                                    </div>
                                </div>
                                <div id="menu1" class="container tab-pane fade">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Business</h4>
                                                    <p style={{color:"black"}}>For inquiries about our corporate training programs and how we can help your organization thrive, please contact us.
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Requesting A Call:</label>
                                                            <p style={{color:"black"}}>+91 8921907007</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>E-mail:</label>
                                                            <p style={{color:"black"}}>manager@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Monday to friday</label>
                                                            <p style={{color:"black"}}>9 am - 8 pm</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Location:</label>
                                                            <p style={{color:"black"}}>Race Course,Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <img src={Contact2} style={{ maxHeight: "300px" ,marginLeft:"60px"}} class="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div id="menu2" class="container tab-pane fade">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h4 class="fw-bold theme-red-text">Job </h4>
                                                    <p style={{color:"black"}}>Are you interested in joining our team? Reach out to us to learn about current job openings!
                                                    </p>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={phoneIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Requesting A Call:</label>
                                                            <p style={{color:"black"}}>+91 9363285910</p>
                                                        </div>
                                                    </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={mailIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>E-mail:</label>
                                                            <p style={{color:"black"}}>career@anglofone.co.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="contact-info d-flex align-items-start">
                                                        <img src={timeIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Monday to friday</label>
                                                            <p style={{color:"black"}}>9 am - 8 pm</p>
                                                        </div>                      </div>
                                                    <div class="contact-info d-flex align-items-start mt-3">
                                                        <img src={locationIcon} />
                                                        <div class="ps-3">
                                                            <label style={{color:"black"}}>Location:</label>
                                                            <p style={{color:"black"}}>Race Course, Coimbatore</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <img src={Contact3} style={{ maxHeight: "300px" }} class="img-fluid-contact" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showArrow && (
    <button
        className="back-to-top"
        onClick={scrollToTop}
        aria-label="Back to top"
    >
        ↑
    </button>
)}
            <section class="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7833.023217976324!2d76.973302!3d11.000189!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85926b79ad9d7%3A0xfa8833d0aa627ecf!2sAnglofone%20Online%20English%20Tamil!5e0!3m2!1sen!2sin!4v1728054993821!5m2!1sen!2sin"
                    width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>


            </section>
            <footer>
                <Footer />
            </footer>

        </div>
    )
}

export default ContactUs;