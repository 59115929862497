import React, { useState } from "react"
import "../CSS/Footer.css"
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaTelegram } from "react-icons/fa";
import whatsappIcon from "../Assets/Images/Tech/Icons/whatsap-white-icon.svg"
import { FaChevronDown,FaChevronUp } from "react-icons/fa";




const Footer = () => {

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [isCoursesVisible, setIsCoursesVisible] = useState(false); // Manage visibility of courses section

  const toggleCoursesVisibility = () => {
    setIsCoursesVisible(!isCoursesVisible); // Toggle visibility of courses
  };



  const handleLanguageSelect = (languageUrl) => {
    // Directly navigate to the provided URL
    window.location.href = languageUrl;
  };


  function sendEmail() {
    console.log("the email is sent")
  }
  return (
    <div className="container">
      <div className="column footer-links">
        {/* Quick Links */}
        <ul className="col-12 col-md-3 quick-links">
          <li>Quick Links</li>
          <li>
            <a href="/home">Home</a>
          </li>
          <li>
          <div className="courses-toggle">
      <button onClick={toggleCoursesVisibility} className="courses-button">
        <span>Courses </span>{isCoursesVisible ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {/* Expandable courses list */}
      {isCoursesVisible && (
        <ol className="courses-list">
          <div className="course-item fontint"><a href="/Course/Foundation">Foundation</a></div>
          <div className="course-item fontint"><a href="/Course/Beginner">Beginner</a></div>
          <div className="course-item fontint"><a href="/Course/Mediate">Mediate</a></div>
          <div className="course-item fontint"><a href="/Course/Speak-Out">Speak Out</a></div>
        </ol>
      )}
    </div>
  </li>
          <li>
            <a href="/Aboutus">About Us</a>
          </li>
          <li>
            <a href="/ContactUs">Contact Us</a>
          </li>
          <li>
            <a href="/Career">Career</a>
          </li>
          <li>
            <a href="/TermsAndConditions">Terms and Conditions</a>
          </li>
          <li>
            <a href="/PrivacyPolicy">Privacy Policy</a>
          </li>
          <li>
            <a href="/RefundPolicy">Refund Policy</a>
          </li>

        </ul>

        {/* Branches */}
        {/* Branches Section */}
        <div className="col-12 col-md-9 branches-section">
          <h4 className="branches-heading">Branches</h4>
          <div className="row">
            <ul className="col-12 col-md-4">
              <li>
                <strong>
                  <p className="fw-bold branch-name">
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: `rgb(180 28 4)` }} /> Tamil Nadu
                  </p>
                </strong>
                <p className="branch-info"><span className="label">📞 Mobile:</span> +91  93632 85910</p>
                <p className="branch-info"><span className="label">📧 Email:</span>career@anglofone.co.in</p>
                <p className="branch-info"><span className="label">📍 Address:</span>EVS Complex, D No: 75 - 76; 2B, 2nd floor, Near six corner, Dr. Radhakrishnan Road, Tatabad, Coimbatore - 641012</p>
              </li>
            </ul>
            <ul className="col-12 col-md-4">
              <li>
                <strong>
                  <p className="fw-bold branch-name">
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: `rgb(180 28 4)` }} /> Kerala
                  </p>
                </strong>
                <p className="branch-info"><span className="label">📞 Mobile:</span> +91 6382059308</p>
                <p className="branch-info"><span className="label">📧 Email:</span>admin@anglofone.co.in</p>
                <p className="branch-info"><span className="label">📍 Address:</span>EVS Complex, D No: 75 - 76; 2B, 2nd floor, Near six corner, Dr. Radhakrishnan Road, Tatabad, Coimbatore - 641012</p>
              </li>
            </ul>
            <ul className="col-12 col-md-4">
              <li>
                <strong>
                  <p className="fw-bold branch-name">
                    <FontAwesomeIcon icon={faLocationDot} style={{ color: `rgb(180 28 4)` }} /> Hyderabad
                  </p>
                </strong>
                <p className="branch-info"><span className="label">📞 Mobile:</span> +91 75698 30539</p>
                <p className="branch-info"><span className="label">📧 Email:</span>hrhyd@anglofone.in</p>
                <p className="branch-info"><span className="label">📍 Address:</span> Space and more business park @ madhapur #3 1-89/A/8/C/2, Adjacent to Vaishnavi Pride Apartments, Vittal Rao Nagar,Madhapur.Hyderabad Telangana - 500081</p>
              </li>
            </ul>
          </div>
        </div>

      </div>

      {/* Footer Bottom */}
      <hr className="hrline"></hr>
      <p className="copyright">©2024. All rights reserved.</p>
      <a
        href="#"
        className="float"
        data-bs-toggle="modal"
        data-bs-target="#customModal"
      >
        Let's Chat <img src={whatsappIcon} alt="WhatsApp Icon" />
      </a>

      {/* Custom Modal */}
      <div className="modal fade" id="customModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content custom-modal-content">
            <div className="modal-header custom-modal-header">
              <h5 className="modal-title">Select Language</h5>
              <i
                className="custom-close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </i>
            </div>
            <div className="modal-body custom-modal-body">
              <ul className="language-list">
                <li
                  onClick={() => handleLanguageSelect("https://wa.link/cd6igd")}
                  className="language-item"
                >
                  Malayalam
                </li>
                <li
                  onClick={() => handleLanguageSelect("https://wa.link/rirnn2")}
                  className="language-item"
                >
                  Tamil
                </li>
                <li
                  onClick={() => handleLanguageSelect("https://wa.link/4la105")}
                  className="language-item"
                >
                  Telugu
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;