import React from "react";
import "../CSS/HomePage.css"
import "../CSS/HomePageCourse.css"
import course1 from "../Assets/Images/Tech/Course/beginner course.png"
import course2 from "../Assets/Images/Tech/Course/2.jpg"
import course3 from "../Assets/Images/Tech/Course/3.jpg"
import course4 from "../Assets/Images/Tech/Course/4.jpg"
import course5 from "../Assets/Images/Tech/Course/5.jpg"
import course6 from "../Assets/Images/Tech/Course/6.jpg"
import course7 from "../Assets/Images/Tech/Course/7.jpg"
import course8 from "../Assets/Images/Tech/Course/foundation course.png"
import arrow from "../Assets/Images/Tech/Course/readmore-arrow-icon.svg"



const HomePageCourse = () => {
    return (
        <section className="section-course">
            <div className="container">
                <div className="course-wrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>We are Building
                                <br></br>
                                <span className="theme-red-text"> The finest English just for you</span>
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                            <div className="course-box">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={course8} className="img-fluid" alt="course-img" />
                                    </div>
                                    <div className="overlay">
                                        <div className="course-details">
                                            <h4>Foundation Course</h4>
                                            <p style={{fontWeight:"bold"}}>You will learn basic grammar and daily life words.</p>
                                            <a href="/Course/Foundation">Read More
                                                <img src={arrow} alt="arrow" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                            <div className="course-box">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={course1} className="img-fluid" alt="course-img" />
                                    </div>
                                    <div className="overlay">
                                        <div className="course-details">
                                            <h4>Beginner Course</h4>
                                            <p style={{fontWeight:"bold"}}>You will learn sentence formation and daily life communication</p>
                                            <a href="/Course/Foundation">Read More
                                                <img src={arrow} alt="arrow" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                            <div className="course-box">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={course4} className="img-fluid" alt="course-img" />
                                    </div>
                                    <div className="overlay">
                                        <div className="course-details">
                                            <h4>Mediate Course</h4>
                                            <p style={{fontWeight:"bold"}}>You will enhance your grammar, vocabulary and speak confidently.</p>
                                            <a href="/Course/Mediate">Read More
                                                <img src={arrow} alt="arrow" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-12 col-sm-12">
                            <div className="course-box">
                                <div className="row">
                                    <div className="col-12">
                                        <img src={course7} className="img-fluid" alt="course-img" />
                                    </div>
                                    <div className="overlay">
                                        <div className="course-details">
                                            <h4>Speak-Out Course</h4>
                                            <p style={{fontWeight:"bold"}}>A customized syllabus for speaking practice to speak effectively.</p>
                                            <a href="/Course/Speak-Out">Read More
                                                <img src={arrow} alt="arrow" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomePageCourse;
