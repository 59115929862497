import "../CSS/TermsAndConditions.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";

const RefundPolicy = () => {
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <div>
            <NavBar />
            <div className="terms-container">
                <h1 className="terms-heading">Refund<span> Policy
                </span></h1>
                <div className="custom-underline"></div>
                <p className="company-name">AngloFone EdTech Pvt. Ltd</p>

               

                <p className="intro">
                Thank you for choosing AngloFone EdTech Pvt. Ltd for your educational needs. We appreciate your trust in our services. This Refund Policy explains the terms and conditions under which refunds are provided by AngloFone EdTech Pvt. Ltd.
                </p>

                <div className="terms-content">
                    <section>
                        <h2>1. Refund Eligibility</h2>

                        <ul>
                            <li>
                                <p>Valid Reasons: Refunds will be considered only for valid reasons, including but not limited to:
                                    <ul>
                                        <li>Technical issues preventing course access.</li>
                                        <li>Unforeseen personal circumstances preventing participation.</li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p>
                                Course Completion: No refunds will be issued if you crossed Day 7 class or if the course is completed, and materials have been delivered or accessed.  
                                </p>
                            </li>
                            <li>
                                <p>
                                Course Cancellation: In the event that AngloFone EdTech cancels a course, you are entitled to a full refund of the course fee. 
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>2. Refund requests will be processed as follows:</h2>

                        <ul>
                            <li>
                                <p>
                                We will assess the request based on the classes attended.
                                </p>
                            </li>
                            <li>
                                <p>
                                The refund amount will be adjusted accordingly, deducting fees for the classes attended.
                                </p>
                            </li>
                            <li>
                                <p>
                                Refunds will be issued to the original payment method within 48hrs of approval.
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>3. Course Cancellation</h2>
                        <ul>
                            <li>
                                <p>
                                We will make all reasonable efforts to notify affected individuals of the course cancellation and process the refund in a timely manner.
                                </p>
                            </li>

                        </ul>


                    </section>

                    <section>
                        <h2>4. Non-Refundable Items</h2>
                        <ul>
                            <li>
                                <p>
                                The following items are non-refundable:
                                </p>
                                <ul>
                                    <li>
                                    Registration fees.
                                    </li>
                                    <li>
                                    Materials or resources that have been accessed or downloaded.
                                    </li>
                                </ul>
                            </li>
                           
                        </ul>

                    </section>

                    <section>
                        <h2>5. Contact Information</h2>
                        <ul>
                            <li>
                                <p>
                                For refund inquiries or to submit a refund request, please contact [manager@anglofone.co.in].
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>6. Policy Changes</h2>
                        <ul>
                            <li>
                                <p>
                                AngloFone EdTech Pvt. Ltd reserves the right to modify or amend this Refund Policy at any time. Any changes will be effective immediately upon posting on our website.
                                </p>
                            </li>
                            
                        </ul>


                    </section>
                    {showArrow && (
    <button
        className="back-to-top"
        onClick={scrollToTop}
        aria-label="Back to top"
    >
        ↑
    </button>
)}

                   
                </div>

                <p className="conclusion">
                By enrolling in a course with AngloFone EdTech Pvt. Ltd, you acknowledge and agree to the terms of this Refund Policy.
                </p>
                <p className="conclusion">Thank you for choosing AngloFone EdTech Pvt Ltd.</p>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default RefundPolicy;
