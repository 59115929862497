import React, { useEffect } from "react";
import NavigationBar from "../NavBar";
import "../../CSS/Courses.css"
import aboutus from "../../Assets/Images/AboutUs/about-img.svg"
import { useLocation } from "react-router-dom";
import rightArrow from "../../Assets/Images/Tech/Icons/right-white-arrow-icon.svg"
import advisorImage from "../../Assets/Images/Tech/Course/why/2.png"
import advisorgif from "../../Assets/Images/Tech/Course/Phonics course.gif"
import faqImg from "../../Assets/Images/Tech/AboutUs/FAQ new.mp4"
import Footer from "../Footer";


const PhonicsCourse = () => {

    const loc = useLocation()


    useEffect(() => {

        const { courseName } = loc.state ? loc.state : {};
        console.log("the course is", courseName)

    }, [])


    return (
        <div>
            <NavigationBar navIndex="Courses" />
            <div>
                <div className="container-fluid mainContainer_p">
                    <section className="grow-banner">
                        <div id="carouselExampleIndicators" className="carousel slide pointer-event" data-bs-ride="carousel">
                            <div className="carousel-inner ">
                                <div className="carousel-item about-banner_p active">
                                    <div className="container">
                                        <div className="banner align-items-center">
                                            <div className="banner-in ">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {/* <h1>Phonics Course</h1> */}
                                                        <nav aria-label="breadcrumb">
                                                            <ol className="breadcrumb">
                                                                <li className="breadcrumb-item">
                                                                    {/* <a href="index.html">Home</a> */}
                                                                </li>
                                                                <li className="breadcrumb-item active" aria-current="page">
                                                                    {/* <a href="index.html">Phonics Course</a> */}
                                                                </li>
                                                            </ol>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
                <section className="section-why-choose-us light-red-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="pe-3">
                                    <img src={advisorgif} className="img-fluid" />
                                </div>

                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <h6>Course Info</h6> */}
                                        <h2>Learn English
                                            <br></br>
                                            <span className="text-primary">Any Where ... Any Time...</span>
                                        </h2>
                                        <div className="bullet-arrow">
                                            <ul>
                                                <li>Join our Anglofone beginner course for an exciting English adventure. Dive into daily video
                                                    lectures.
                                                </li>
                                                <li>Engaging skill-enhancing tasks</li>
                                                <li>Interactive call-based practice sessions.</li>
                                                <li>Elevate your communication skills through live sessions that challenge you to step out of your
                                                    comfort zone.
                                                </li>
                                                <li>Dedicated trainer support.</li>
                                                <li>Start your journey towards English proficiency today!
                                                </li>
                                                <li>The course fee starts from 4500 INR (Offers are applicable under specific circumstances)
                                                </li>
                                            </ul>
                                            <button className="btn btn-danger btn-theme d-block mt-4" type="submit">
                                                {`${"Join Now "}`}
                                                <img src={rightArrow} alt="grow" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-why-choose-us pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2>Who Can
                                            <br></br>
                                            <span className="text-primary fw-light">Choose this Course</span>
                                        </h2>
                                        <div className="bullet-arrow">
                                            <ul>
                                                <li style={{ fontWeight: "700" }}>Join our Anglofone beginner course for an exciting English adventure. Dive into daily video
                                                    lectures.
                                                </li>
                                                <li style={{ fontWeight: "700" }}>Engaging skill-enhancing tasks</li>
                                                <li style={{ fontWeight: "700" }}>Interactive call-based practice sessions.</li>
                                                <li style={{ fontWeight: "700" }}>Elevate your communication skills through live sessions that challenge you to step out of your
                                                    comfort zone.
                                                </li>
                                            </ul>
                                            <button className="btn btn-danger btn-theme d-block mt-4" type="submit">
                                                {`${"Join Now "}`}
                                                <img src={rightArrow} alt="grow" />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-3">
                                <div className="pe-3">
                                    <img src={advisorImage} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faq */}
                <section className="section-faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                            <video src={faqImg} className="img-fluid d-block mx-auto" autoPlay loop muted  >
                            </video>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-10">
                                        <h6>FAQ</h6>
                                        <h2 className="mb-2"> Frequently asked questions</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quisquam alias enim voluptas aliquam nihil tenetur nemo quibusdam velit maxime animi repudiandae quas sequi illum, earum amet! Itaque, omnis eos. Nemo.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        At what time will my English class commence?
                                                    </button>
                                                </h4>
                                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#ccordionExample">
                                                    <div className="accordion-body">

                                                        At Anglofone, we have flexibly structured our online English courses. You can learn spoken English
                                                        in your leisure time through WhatsApp. Your dedicated trainers will guide you by adapting to your
                                                        schedules.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Will I able to speak fluently within 2 months?
                                                    </button>
                                                </h4>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Learning pace is something that differs from person to person. Our course duration is 2 months. In
                                                        addition to that, your course is valid up to 9 months from which you can become more fluent and
                                                        learn beyond the course even after your course duration.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What is the procedure to enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can text the mentioned contact number on our website. Our course advisor will reach you. There
                                                        will be an online test to evaluate your level in English. After evaluating your test, our advisors
                                                        will suggest the suitable course to you, considering
                                                        your level of English.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h4 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        I'm working abroad. How can I enroll in the course?
                                                    </button>
                                                </h4>
                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        You can access our English classes from any part of the world because our trainers are working
                                                        round the clock. We can definitely coordinate with your timings.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-why-choose-us section-testimonial-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h6>Testimonials</h6>
                                        <h2>What
                                            <span class="text-primary "> People&nbsp;Say</span>
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="text-center">Need to pace slider </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>

    )
}

export default PhonicsCourse;