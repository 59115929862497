import "../CSS/TermsAndConditions.css";
import NavBar from "./NavBar";
import Footer from "./Footer";
import React, { useState, useEffect } from "react";

const PrivacyPolicy = () => {
    const [showArrow, setShowArrow] = useState(false);

    // Handle scroll events
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowArrow(true);
                console.log("scroll event");
            } else {
                setShowArrow(false);
                console.log("scroll event false");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    
    return (
        <div>
            <NavBar />
            <div className="terms-container">
                <h1 className="terms-heading">Privacy<span> Policy
                </span></h1>
                <div className="custom-underline"></div>
                <p className="company-name">AngloFone EdTech Pvt. Ltd</p>

                <p>Last Updated on: 24.11.23</p>

                <p className="intro">
                Welcome to AngloFone EdTech's WhatsApp English Learning Module . This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By accessing our website or using our services, you agree to the terms of this Privacy Policy.
                </p>

                <div className="terms-content">
                    <section>
                        <h2>1. Information We Collect</h2>

                        <ul>
                            <li>
                                <p>Personal Information: We may collect personal information, including but  limited to names, email addresses, contact numbers, and other relevant details provided by users during the registration process.</p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>2. How We Use Your Information</h2>

                        <ul>
                            <li>
                                <p>
                                Personalization: We use the collected information to personalize your learning experience, including providing relevant course recommendations and communication.
                                </p>
                            </li>
                            <li>
                                <p>
                                Communication: Your contact information may be used to send important updates, newsletters, course delivery and other relevant information.
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>3. Information Sharing and Disclosure</h2>
                        <ul>
                            <li>
                                <p>
                                Third-Party Services: We may engage third-party services for analytics, communication, or other purposes. These parties have access to your information only to perform specific tasks and course delivery on our behalf.
                                </p>
                            </li>
                            <li>
                                <p>
                                Legal Requirements: We may disclose your information if required by law or in response to valid requests by public authorities.
                                </p>
                            </li>
                        </ul>


                    </section>

                    <section>
                        <h2>4. Your Privacy Choices</h2>
                        <ul>
                            <li>
                                <p>
                                Opt-Out: You may opt out of receiving promotional communications from us by following the unsubscribe link in our emails.
                                </p>
                            </li>
                            <li>
                                <p>
                                Access and Update: You can access and update your personal information by contacting us at admin@anglofone.co.in
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>5. Data Security</h2>
                        <ul>
                            <li>
                                <p>
                                Security Measures: We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>6. Cookies and Similar Technologies</h2>
                        <ul>
                            <li>
                                <p>
                                Cookies: We use cookies to enhance your experience on our website. You can control or block cookies through your browser settings.
                                </p>
                            </li>
                            
                        </ul>


                    </section>

                    <section>
                        <h2>7. External Links</h2>
                        <ul>
                            <li>
                                <p>
                                Third-Party Websites: Our website may contain links to external sites. We are not responsible for the content or privacy practices of these sites.
                                </p>
                            </li>
 
                        </ul>


                    </section>

                    <section>
                        <h2>8. Children's Privacy</h2>
                        <ul>
                            <li>
                                <p>Age Restriction: Our services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children.</p>
                            </li>
                        </ul>

                    </section>
                    {showArrow && (
    <button
        className="back-to-top"
        onClick={scrollToTop}
        aria-label="Back to top"
    >
        ↑
    </button>
)}
                    <section>
                        <h2>9. Changes to This Privacy Policy</h2>
                        <ul>
                            <li>
                                <p>
                                Updates: We may update this Privacy Policy to reflect changes in our practices. Please review this page periodically for any changes.

                                </p>
                            </li>
                        </ul>

                    </section>

                    <section>
                        <h2>10. Contact Us</h2>
                        <ul>
                            <li>
                                <p>
                                Questions: If you have any questions or concerns regarding our Privacy Policy, please contact us at manager@anglofone.co.in
                                </p>
                            </li>
                        </ul>

                    </section>
                </div>

                <p className="conclusion">
                By using AngloFone EdTech's services, you agree to the terms outlined in this Privacy Policy.
                </p>
                <p className="conclusion">Thank you for choosing AngloFone EdTech Pvt Ltd.</p>
            </div>
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default PrivacyPolicy;
